import React, { CSSProperties, useEffect, useState } from 'react'
import ARROW_RIGHT from './../../assets/images/arrow-outline-right.svg';
import { animated, AnimatedProps, useSpringRef, useTransition } from 'react-spring';

import IMG_MEMBERPLANET from '././../../../src/assets/images/Project-Images/project-1.png';
import IMG_ETV from '././../../../src/assets/images/Project-Images/project-3.png';
import IMG_CLANWORLD from '././../../../src/assets/images/Project-Images/project-6.png';
import IMG_GEOREP from '././../../../src/assets/images/Project-Images/project-5.png';
import IMG_ONEMONEY from '././../../../src/assets/images/Project-Images/project-2.png';
import IMG_KOGO from '././../../../src/assets/images/Project-Images/project-4.png';

const CaseStudyPage = () => {
    let previousTimer: NodeJS.Timer;
    const onNext = () => {
        let slide = headingIndex + 1;
        if (slide > pages.length - 1) {
            slide = 0;
        }

        setHeadingIndex(slide);
        setPlayAnimation(true);
        if (previousTimer) {
            clearTimeout(previousTimer);
        }
    }
    const [headingIndex, setHeadingIndex] = useState(0);
    const [imageIndex, setImageIndex] = useState(0);
    const [playAnimation, setPlayAnimation] = useState(false);

    const clientImages = [
        { img: IMG_MEMBERPLANET },
        { img: IMG_ETV },
        { img: IMG_CLANWORLD },
        { img: IMG_GEOREP },
        { img: IMG_ONEMONEY },
        { img: IMG_KOGO },
    ];
    const pages: ((props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement)[] = [
        ({ style }) => <animated.div className="position-absolute px-3 py-5 px-lg-5 m-bg-primary border-r-lg h-100 w-150 w-sm-100" style={{ ...style, zIndex: 5 }}>
            <div className="position-relative pl-sm-unset" style={{ paddingLeft: '25%' }}>
                <h1 className='m-heading-2 text-white'>MemberPlanet - Sitebuilder</h1>
                <p className='text-white'>A proud collaboration with Memberplanet to design and build a dynamic SiteBuilder which is easy to create a premium, professionally appealing site with no prior web design experience.</p>
                <p className='text-white'>An advanced tool which is easily integrated into a hub of vast features of eco system of Memberplanet.</p>
                <p className='text-white d-none d-lg-block'>Sitebuilder is a tool that provides numerous themes and user-friendly editor to create a professional website with no prior experience.</p>
                <ul className='d-none d-lg-block'>
                    <li><strong>Drag-and-drop editor</strong></li>
                    <li><strong>Customizable themes</strong></li>
                    <li><strong>Mobile-optimized pages</strong></li>
                </ul>
            </div>
        </animated.div>,
        ({ style }) => <animated.div className="position-absolute px-3 py-5 px-lg-5 m-bg-primary border-r-lg h-100 w-150 w-sm-100" style={{ ...style, zIndex: 5 }}>
            <div className="position-relative pl-sm-unset" style={{ paddingLeft: '25%' }}>
                <h1 className='m-heading-2 text-white'>ETV Bharat - News Platform</h1>
                <p className='text-white'>ETV Bharat is a comprehensive digital national news platform conceived to deliver seamless news and information services, using video-centric Web
                    Portals and Mobile App. </p>
                <p className='text-white'>It is first-of-its kind offering in India in terms of diversity and depth, available in 13 languages </p>
                <p className='text-white d-none d-lg-block'>i.e.– Hindi, Urdu, Telugu, Tamil, Kannada, Malayalam, Gujarati, Marathi, Bengali, Punjabi, Assamese, Odia and English</p>
            </div>
        </animated.div>,
        ({ style }) => <animated.div className="position-absolute px-3 py-5 px-lg-5 m-bg-primary border-r-lg h-100 w-150 w-sm-100" style={{ ...style, zIndex: 5 }}>
            <div className="position-relative pl-sm-unset" style={{ paddingLeft: '25%' }}>
                <h1 className='m-heading-2 text-white'>Clanworld - Live Streaming</h1>
                <p className='text-white'>A seamless and easy to use Live streaming platform where creators, artists, influencers and skilled professionals can perform live events and their fans can view them exclusively by buying a ticket to the event.</p>
                <p className='text-white'>A complete and robust and secure live streaming platform on Mobile and web and plugin any live streaming tool or software easily to stream live content.</p>
            </div>
        </animated.div>,
        ({ style }) => <animated.div className="position-absolute px-3 py-5 px-lg-5 m-bg-primary border-r-lg h-100 w-150 w-sm-100" style={{ ...style, zIndex: 5 }}>
            <div className="position-relative pl-sm-unset" style={{ paddingLeft: '25%' }}>
                <h1 className='m-heading-2 text-white'>GeoRep - Sales CRM</h1>
                <p className='text-white'>All-in-one Field Sales App Solution.<br />
                    The simplest and most cost-effective way to enable Field Sales Staff and Managers.<br />
                    Reduce admin, improve process, and serve customers better.</p>
                <ul>
                    <li>Live GPS tracking</li>
                    <li>Manage Sales Reps tasks</li>
                    <li>Call cycle calendar</li>
                    <li>Automated time sheets</li>
                    <li>Sales orders & Quotes</li>
                    <li>Create Custom Forms and Contracts</li>
                </ul>
            </div>
        </animated.div>,
        ({ style }) => <animated.div className="position-absolute px-3 py-5 px-lg-5 m-bg-primary border-r-lg h-100 w-150 w-sm-100" style={{ ...style, zIndex: 5 }}>
            <div className="position-relative pl-sm-unset" style={{ paddingLeft: '25%' }}>
                <h1 className='m-heading-2 text-white'>OneMoney - Financial Application</h1>
                <p className='text-white'>India's First Account Aggregator, Licensed by RBI</p>
                <p className='text-white'>A framework build as a sustainable, trust and consent based sharing of data between citizens and enterprises. </p>
                <p className='text-white'>A user experience tools with a privacy-first approach for users to manage their consents.</p>
                <p className='text-white d-none d-lg-block'>Pool of reliable API for the hundreds of financial
                    service providers across the country to use, for secure consent-driven receipt and transfer of their users' data.</p>
            </div>
        </animated.div>,
        ({ style }) => <animated.div className="position-absolute px-3 py-5 px-lg-5 m-bg-primary border-r-lg h-100 w-150 w-sm-100" style={{ ...style, zIndex: 5 }}>
            <div className="position-relative pl-sm-unset" style={{ paddingLeft: '25%' }}>
                <h1 className='m-heading-2 text-white'>KOGO - Gamification</h1>
                <p className='text-white'><small>world's first Social Travel Currency - KOGOCOIN</small></p>
                <p className='text-white'>Pay with KOGOCOIN on every transaction always getting the REAL-PRICE on Hotels, Flights, Activities & Cool hand-picked Products!</p>
            </div>
        </animated.div>,

    ];
    const transRef = useSpringRef();
    const transitions = useTransition(headingIndex, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, right: -65, top: -20, config: { duration: 1500 } },
        enter: { opacity: 1, right: -50, top: 0, config: { duration: 500 } },
        leave: { opacity: 0, top: 100, zIndex: 6, config: { duration: 1000 } },
    });
    const mobTransRef = useSpringRef();
    const mobTransitions = useTransition(headingIndex, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, top: -20, config: { duration: 1500 } },
        enter: { opacity: 1, top: 0, config: { duration: 500 } },
        leave: { opacity: 0, top: 100, zIndex: 6, config: { duration: 1000 } },
    });
    useEffect(() => {
        transRef.start();
        mobTransRef.start();

        setTimeout(() => {
            setImageIndex(headingIndex);
        }, 500);
        if (previousTimer) {
            clearTimeout(previousTimer);
        }
        previousTimer = setTimeout(() => {
            onNext();
        }, 5000);
        return () => {
            clearTimeout(previousTimer);
        }
    }, [headingIndex]);

    const onEndClientLogoAnimation = () => {
        setPlayAnimation(false);
    }

    return (
        <div className='case-study p-lg-5 pb-5'>
            <div className="container pt-5">
                <h2 className='text-white d-block d-lg-none'>We have been honored to collaborate on challenging business applications.</h2>
                <div className="row">
                    <div className="col-lg-6 position-relative">
                        <img onAnimationEnd={onEndClientLogoAnimation} className={`d-none d-lg-block position-absolute ${playAnimation ? 'anim-Fade-Out-In' : null}`} style={{ bottom: 60, right: 50, zIndex: 7, height: 550, objectFit: 'contain', objectPosition: 'bottom' }} src={clientImages[imageIndex].img} alt="" />
                        <img onAnimationEnd={onEndClientLogoAnimation} className={`d-block d-lg-none ${playAnimation ? 'anim-Fade-Out-In' : null} w-100`} style={{ bottom: 60, right: 50, zIndex: 7, objectFit: 'contain', objectPosition: 'center', height: 220 }} src={clientImages[imageIndex].img} alt="" />
                    </div>
                    <div className="col-lg-6">
                        <h2 className='text-white d-none d-lg-block' style={{ marginBottom: 100 }}>We have been honored to collaborate on challenging business applications.</h2>
                        <div className='d-none d-lg-block position-relative border-r-lg py-5 mb-5' style={{ height: 420 }}>
                            {
                                transitions((style, i) => {
                                    const Page = pages[i]
                                    return <Page style={style} />
                                })
                            }
                            <div className="position-absolute p-5 m-bg-primary border-r-lg h-100" style={{ width: '150%', right: -65, top: -20, opacity: 0.3 }}></div>
                            <div className="position-absolute p-5 m-bg-primary border-r-lg h-100" style={{ width: '150%', right: -80, top: -40, opacity: 0.3 }}></div>
                        </div>
                        <div className='d-block d-lg-none position-relative border-r-lg py-5 mb-5' style={{ height: 400 }}>
                            {
                                mobTransitions((style, i) => {
                                    const Page = pages[i]
                                    return <Page style={style} />
                                })
                            }
                            <div className="d-none d-lg-block position-absolute p-5 m-bg-primary border-r-lg w-150 w-sm-100 h-100" style={{ width: -65, top: -20, opacity: 0.3 }}></div>
                            <div className="d-none d-lg-block position-absolute p-5 m-bg-primary border-r-lg w-150 w-sm-100 h-100" style={{ width: -80, top: -40, opacity: 0.3 }}></div>
                        </div>
                    </div>
                </div>
                <div className="d-flex text-white mb-5 justify-content-end">
                    {/* <div className='me-5'>
                        <img src={ARROW_LEFT} alt="" className='me-3'></img> Previous
                    </div> */}
                    <div className='ms-5 cursor' onClick={onNext}>
                        Next<img src={ARROW_RIGHT} alt="" className='ms-3'></img>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CaseStudyPage;