import React from 'react';
import './App.css';
import './assets/styles/css/style.css';
import HomePage from './app/pages/home';
import 'aos/dist/aos.css';

function App() {
  return (
    <HomePage></HomePage>
  );
}

export default App;
