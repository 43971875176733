import React, { useEffect } from 'react'
import ETV_IMG from '../../assets/images/clientsimages/bharat.png';
import MEMBERPLANET_IMG from '../../assets/images/clientsimages/memberplanet.png';
import TAJRUMMY_IMG from '../../assets/images/clientsimages/taj.png';
import SAVITR_IMG from '../../assets/images/clientsimages/savitr.png';
import ADDA_IMG from '../../assets/images/clientsimages/adda52.png';
import SARANYU_IMG from '../../assets/images/clientsimages/saranyu-squarelogo.png';
import ONEMONEY_IMG from '../../assets/images/clientsimages/onemoney.png';
import ZENSOFT_IMG from '../../assets/images/clientsimages/zensoftware.png';
import ZENSARK_IMG from '../../assets/images/clientsimages/zensark.png';
import CLANWORLD_IMG from '../../assets/images/clientsimages/clanworld.png';
import KOGO_IMG from '../../assets/images/clientsimages/kogo.png';
import GEOREP_IMG from '../../assets/images/clientsimages/georep.png';
import AOS from 'aos';

const ClientListPage = () => {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (

        <div className="container mt-5 p-lg-5">
            <div className="row mb-1">
                <div className="">
                    <h1 className='m-heading-2 py-1 text-center'>Client List</h1>
                </div>
            </div>

            <div className="d-grid grid-template-6 grid-sm-template-2">
                <div className="border border-sm-bottom-none border-sm-right-none border-lg-right-none border-lg-bottom-none  d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={ETV_IMG} alt="" />
                </div>
                <div className="border border-sm-bottom-none border-lg-right-none border-lg-bottom-none  d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={MEMBERPLANET_IMG} alt="" />
                </div>
                <div className="border border-sm-bottom-none border-sm-right-none border-lg-right-none border-lg-bottom-none  d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={ONEMONEY_IMG} alt="" className="" />

                </div>
                <div className="border border-sm-bottom-none border-lg-right-none border-lg-bottom-none  d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={SAVITR_IMG} alt="" />
                </div>
                <div className="border border-sm-bottom-none border-sm-right-none border-lg-right-none border-lg-bottom-none  d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={ADDA_IMG} alt="" className='' />
                </div>
                <div className="border border-sm-bottom-none border-lg-bottom-none d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={SARANYU_IMG} alt="" className='' />
                </div>
            </div>
            <div className="d-grid grid-template-6 grid-sm-template-2">
                <div className="border border-sm-right-none border-sm-bottom-none border-lg-right-none  d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={TAJRUMMY_IMG} alt="" />
                </div>
                <div className="border border-sm-bottom-none border-lg-right-none  d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={ZENSOFT_IMG} alt="" className="" />
                </div>
                <div className="border border-sm-right-none border-sm-bottom-none border-lg-right-none  d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={ZENSARK_IMG} alt="" />
                </div>
                <div className="border border-sm-bottom-none border-lg-right-none  d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={CLANWORLD_IMG} alt="" className='' />
                </div>
                <div className="border border-sm-right-none border-lg-right-none  d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={KOGO_IMG} alt="" className="" />
                </div>
                <div className="border d-flex align-items-center justify-content-center p-2">
                    <img data-aos='zoom-in' src={GEOREP_IMG} alt="" className="" />
                </div>
            </div>
        </div>

    )
}

export default ClientListPage;