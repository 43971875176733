import React, { CSSProperties, useEffect, useState } from 'react'
import { animated, AnimatedProps, useSpringRef, useTransition } from 'react-spring';
import ARROW_DOWN_IMG from './../../assets/images/arrow-down.svg';
import OFFICE_IMG from './../../assets/images/office.jpeg';
import USER_AVATAR_IMG from './../../assets/images/user-tie.svg';
import WorkTogetherPage from './worktogether';

export const AboutUS = () => {
    const [headingIndex, setHeadingIndex] = useState(0);
    const pages: ((props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement)[] = [
        ({ style }) => <animated.h1 className='m-heading-2 position-absolute' style={{ ...style, transformOrigin: '100% 0%', lineHeight: 1.6 }}>
            We are a software development company specializing in growth
            through strategic and creative thinking and technical expertise.
        </animated.h1>,
        ({ style }) => <animated.h1 className='m-heading-1 position-absolute' style={{ ...style, transformOrigin: '100% 0%', lineHeight: 1.6 }}>
            "We are experts in software development aimed at driving
            growth."
        </animated.h1>,
        ({ style }) => <animated.h1 className='m-heading-2 position-absolute' style={{ ...style, transformOrigin: '100% 0%', lineHeight: 1.6 }}>
            "Our emphasis is on the combination of strategic thinking,
            creative problem-solving, and technical proficiency."
        </animated.h1>,
        ({ style }) => <animated.h1 className='m-heading-1 position-absolute' style={{ ...style, transformOrigin: '100% 0%', lineHeight: 1.6 }}>
            Our goal is to turn ideas into reality through innovative software
            development.
        </animated.h1>,
        ({ style }) => <animated.h1 className='m-heading-1 position-absolute' style={{ ...style, transformOrigin: '100% 0%', lineHeight: 1.6 }}>
            We deliver exceptional services within the realm of opportunities.
        </animated.h1>,
    ];
    const transRef = useSpringRef();
    const transitions = useTransition(headingIndex, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: 'translate(0%,200%)', scale: 0, config: { duration: 1500 } },
        enter: { opacity: 1, transform: 'translate(0%,0%)', scale: 1, config: { duration: 500 } },
        leave: { opacity: 0, transform: 'translate(0%,-100%)', config: { duration: 1000 } },
    });
    useEffect(() => {
        transRef.start();
    }, [headingIndex]);
    useEffect(() => {
        const timerId = setInterval(() => {
            let newIndex = headingIndex + 1;
            if (newIndex > pages.length - 1) {
                newIndex = 0;
            }
            setHeadingIndex(newIndex);
        }, 5000);

        window.scrollTo(0, 0);
        return () => clearInterval(timerId);
    }, []);
    return (
        <div className="container-fluid">

            <div style={{ marginTop: 80 }} className='container about-us'>
                <div className='row'>
                    <div className="col-lg-4 position-relative text-center d-none d-lg-block">
                        <img src={ARROW_DOWN_IMG} alt="" className='position-absolute' style={{ top: '10%' }} />
                    </div>
                    <div className="col-lg-8 overflow-hidden position-relative h-300 h-md-200">
                        {/* <h1 className='m-heading-1 anim-SlideUP'>We’re a strategic, creative growth agency with a love for branding.</h1>
                        <h1 className='m-heading-1 anim-SpawnFromBottom'>We’re a strategic, creative growth agency with a love for branding.</h1> */}
                        {
                            transitions((style: any, i: any) => {
                                const Page = pages[i]
                                return <Page style={style} />
                            })
                        }
                    </div>
                </div>
                <h1 className='m-heading-1 text-center border-bottom pb-3 mb-5'>About Us</h1>
                {/* <h4 className='mt-5'>The Company</h4> */}
                <p>MRSOFT Solutions offer world-class services through agile or traditional methodologies by relying on the most talented pool of engineers in the region.</p>
                <p>We work on diverse projects ranging from simple information systems and websites to complex enterprise type architectures, desktop or web-enabled applications, traditional n-tier and service oriented architectures. We follow three main rules to get to our goals: do it on-time, do it within scope and offer the best service with lower cost.</p>
                <h5 className='my-4'>
                    MRSOFT Solutions uses modern communication and collaboration solutions that helps employees, partners and customers to work better together.
                </h5>
                <p>
                    We are a firm with a creative team in, We are passionate to translate the essence of emotions and thoughts into forms, colours and textures.Our solutions are creative and practical, always guided by a spirit of total quality and customer satisfaction. We execute our projects always with new concepts and thoughts. Therefore, for optimum success of your project we ensure that there is regular communication and continuous quality control.

                </p>
                <p>
                    As our company grows and evolves we remain dedicated to the quality and design we have been offering our clients for series of years and so majority of our business is by reference and repeat clients.


                </p>
                <h4 className='mt-5 heading-pointer position-relative ps-5'>Our mission combines work and talent.</h4>
                <p className='mt-2'>
                    We seek to achieve best results by helping our clients make distinctive improvements.
                    How do we do it?
                </p>
                <p>
                    By leveraging best-of-technology and building a great company that is profitable and appeals to, helps develop and retains exceptional people.
                </p>

                <div className="border-primary border-r-md d-flex overflow-hidden flex-column  flex-lg-row" style={{ marginBottom: 100 }}>

                    <h4 className='p-5 b-primary m-0' style={{ lineHeight: 1.5 }}>
                        <u className='txt-yellow'>Our vision</u> is to develop in a constant manner and grow as a major service provider to become a leading performer, in providing quality solutions provider in the competitive global marketplace.
                    </h4>
                    <h4 className='p-5 m-0 bg-yellow' style={{ lineHeight: 1.5 }}>
                        <u className='text-white'>Our passion</u> for what we do led us here. Our vision guides our business every day: to provide the best business products and services; while taking care of our people and honoring our values.
                    </h4>
                </div>
                <div className="row mb-5">
                    <div className="col-lg-6 bg-yellow ps-lg-5">
                        <h4 className='my-5 heading-pointer position-relative ps-5'>The Team.</h4>
                        <h1>
                            Our workforce is our most important asset.
                        </h1>
                        <p className='mt-5' style={{ color: '#000000' }}>Going by the philosophy of "Creativity at its Best", we strongly believe in making the work environment a delight for the team members.</p>
                        <p className='mt-4' style={{ color: '#000000' }}>We are comprised of highly motivated, trained professionals who are enthusiastically putting in their heart and souls, contributing in their own ways towards the organization's objectives.</p>
                        <p className='mt-4 mb-5' style={{ color: '#000000' }}>Our team is a complete and young breed of professionals from Technology, Marketing and Business Management domains. Our people are some of the most talented, spirited people when it comes to innovating or giving you the right solution.</p>
                    </div>
                    <div className="col-lg-6 p-0">
                        <img src={OFFICE_IMG} alt="" className='w-100 h-100' style={{ objectFit: 'cover' }} />
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-lg-4 text-center border py-5">
                        <img src={USER_AVATAR_IMG} alt="" style={{ height: 100 }} className='mb-4' />
                        <h6>D. Uday Bhaskar</h6>
                        <p>On an enterprise level, having 23+ years of experience in Entrepreneurial-spirited, pioneering technologist, Dynamic, results-oriented leader with a strong track record of performance & Technology in turnaround and high-paced organizations.</p>
                    </div>
                    <div className="col-lg-4 text-center border py-5">
                        <img src={USER_AVATAR_IMG} alt="" style={{ height: 100 }} className='mb-4' />
                        <h6>B. Sanjeev Kumar</h6>
                        <p>The Tech Geek, 12+ years experience in developing various applications, strong influencer for standards of coding and agile processes, capable of building test driven, modular code set to a very high standard for high quality enterprise level applications.</p>
                    </div>
                    <div className="col-lg-4 text-center border py-5">
                        <img src={USER_AVATAR_IMG} alt="" style={{ height: 100 }} className='mb-4' />
                        <h6>B. Vinod Kumar</h6>
                        <p>With a decade of  extensive experience and knowledge in full stack development make him an accomplished developer who can tackle any challenge thrown his way with ease.</p>
                    </div>
                    <div className="col-lg-4 text-center border py-5">
                        <img src={USER_AVATAR_IMG} alt="" style={{ height: 100 }} className='mb-4' />
                        <h6>Pavan Kumar</h6>
                        <p>As a 10-year experienced full stack developer, he possess a wealth of knowledge and expertise in building web applications from front-end to back-end. Pavan have honed his skills in best coding languages</p>
                    </div>
                    <div className="col-lg-4 text-center border py-5">
                        <img src={USER_AVATAR_IMG} alt="" style={{ height: 100 }} className='mb-4' />
                        <h6>Prashanth Battu</h6>
                        <p>As a 10-year experienced fullstack team player, possess a deep understanding of all aspects of business application development, and you have a proven ability to manage project timelines, budgets, and resources.</p>
                    </div>
                    <div className="col-lg-4 text-center border py-5">
                        <img src={USER_AVATAR_IMG} alt="" style={{ height: 100 }} className='mb-4' />
                        <h6>Prashant Akkireddy</h6>
                        <p>As a highly skilled developer with experience in UI UX development, he brings a unique perspective to web application development. his expertise in front-end technologies and design principles, enables to create highly engaging and intuitive user experiences.</p>
                    </div>
                    <div className="col-lg-4 text-center border py-5">
                        <img src={USER_AVATAR_IMG} alt="" style={{ height: 100 }} className='mb-4' />
                        <h6>Gogu Nageswara Rao</h6>
                        <p>As a 5-year experienced mobile app developer, he possess a deep understanding of mobile app development best practices and have a track record of creating high-quality applications for both Android and iOS platforms.</p>
                    </div>
                    <div className="col-lg-4 text-center border py-5">
                        <img src={USER_AVATAR_IMG} alt="" style={{ height: 100 }} className='mb-4' />
                        <h6>Nagarjuna G</h6>
                        <p>Expert in both Angular and React, coupled with your strong UI/UX design skills, make him a valuable asset to any organization looking to develop engaging and intuitive web applications.</p>
                    </div>
                </div>

                {/* <h4 className='mt-5'>Vision</h4> */}

            </div>
            <div className="pt-4 border-top">
                <WorkTogetherPage></WorkTogetherPage>
            </div>
        </div>
    )
}
