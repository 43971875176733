import React, { useState } from "react";
import ArrowDown2 from "./../../assets/images/ico-downarrow2.svg";
import IMG_ARROW_RIGHT from "./../../assets/images/arrow-right-small.svg";
import Linkedin from "./../../assets/images/social-icons/ico-lin-blk.svg";
import Facebook from "./../../assets/images/social-icons/ico-fb-blk.svg";
import Twitter from "./../../assets/images/social-icons/ico-twi-blk.svg";
import Btnsubs from "./../../assets/images/btn-subs.svg";
import Icocopyright from "./../../assets/images/ico-copyright.svg";
import { Link } from "react-router-dom";
import { animated, config, useChain, useSpring, useSpringRef, useTransition } from "react-spring";
import IMG_MEMBERPLANET from '././../../../src/assets/images/case-study/site-builder.jpg';
import IMG_ETV from '././../../../src/assets/images/case-study/etv.jpg';
import IMG_CLANWORLD from '././../../../src/assets/images/case-study/clanworld.jpg';
import IMG_GEOREP from '././../../../src/assets/images/case-study/georep.jpg';
import IMG_ONEMONEY from '././../../../src/assets/images/case-study/onemoney1.jpg';
import IMG_KOGO from '././../../../src/assets/images/case-study/kogo.jpg';

const WorkTogetherPage = () => {
  const teamImages = [IMG_MEMBERPLANET, IMG_ETV, IMG_CLANWORLD, IMG_GEOREP, IMG_ONEMONEY, IMG_KOGO];
  const [showTeam, setShowTeam] = useState(false);
  const springApi = useSpringRef();
  const { size, ...rest } = useSpring({
    ref: springApi,
    config: config.stiff,
    from: { size: '0%' },
    to: {
      size: showTeam ? '100%' : '0%',
      // background: showTeam ? 'white' : 'hotpink',
    },
  });
  const transApi = useSpringRef()
  const transition = useTransition(showTeam ? teamImages : [], {
    ref: transApi,
    trail: 400 / teamImages.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  })
  useChain(showTeam ? [springApi, transApi] : [transApi, springApi], [
    0,
    showTeam ? 0.1 : 0.6,
  ])
  return (
    <div className="mt-5">
      <div className="px-5">
        <div className="row pb-5">
          <div className="col-lg-4 position-relative text-center d-none d-lg-block">
            <img src={ArrowDown2} alt="" className="position-absolute" style={{ top: "40%", height: 70, right: '15%' }} />
          </div>
          <div className="col-lg-8">
            <h1 className="m-heading-2"> We would love to hear from you. LET'S WORK - TOGETHER </h1>
          </div>
        </div>
        <div className="row d-flex mt-3 mb-5">
          <div className="col-lg-4 ps-lg-5">
            <p className="text-secondary"><small>WHY US?</small></p>
            <p className="text-black">We use latest tools<br />
              Vast experience<br />
              Quality first<br />
              We understand your need<br />
            </p>
            <Link to={'/about'} className='text-primary'>About us</Link>
            {/* <p className="text-primary cursor" onClick={() => setShowTeam(true)}>Team</p> */}
          </div>
          <div className="col-lg-4">
            <p className="text-secondary"><small>BUSINESS INQUIRIES</small></p>
            <p className="text-black">info@mrsoftsolutions.com</p>
            {/* <p className="text-primary">Contact Us
              <img src={IMG_ARROW_RIGHT} alt="" className="ms-2" />
            </p> */}
            <p className="text-secondary"><small>FOLLOW US</small></p>
            <div className="d-flex" style={{ height: "30px", width: "30px" }}>
              <a target={'_blank'} href="https://www.linkedin.com/company/mrsoftsolutionsind/"><img src={Linkedin} alt="" /></a>
              {/* <img src={Facebook} alt="" className="ms-4" />
              <img src={Twitter} alt="" className="ms-4" /> */}
            </div>
          </div>
          <div className="col-lg-4">
            <p className="text-secondary"><small>INDIA</small></p>
            <p className="text-black">
              722, MANJEERA MAJESTIC HOMES - <br />
              JNTU - Hitech City Road, Kukatpally - <br />
              Hyderabad, Telangana 500085
            </p>
            {/* <p className="text-secondary"><small>STAY UPDATED</small></p>
            <p>Subscribe to get mrsoft solutions updates.</p>
            <p className="border-0 rounded-pill bg-light text-end ">
              <img src={Btnsubs} alt="" />
            </p> */}
          </div>
        </div>
        <div className="border-top border-dark py-3">
          <p className="text-center">
            <img src={Icocopyright} alt="" className="p-2" />
            2023 Mrsoft Solutions 
            {/* | Privacy Policy */}
            </p>
        </div>
      </div>
      <animated.div
        className="team-container"
        style={{ ...rest, height: size }}
        onClick={() => setShowTeam(!showTeam)}>
        {/* <div className="team-container"> */}
        {transition((style: any, item) => (
          <animated.div className="team-img"
            style={{ ...style }}
          >
            <img src={item} alt="" />
          </animated.div>
        ))}
        {/* </div> */}
      </animated.div>
    </div>
  );
};

export default WorkTogetherPage;
