import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { animated, AnimatedProps, useSpringRef, useTransition } from 'react-spring';
import ARROW_DOWN_IMG from './../../assets/images/arrow-down.svg';
// import PLAY_REEL_BTN from './../../assets/images/btn-play-reel.svg';
import CaseStudyPage from './case-study';
// import Portfolio from './portfolio';
import NewServicesPage from './new-services';
import ClientListPage from './clientlist'
import WorkTogetherPage from './worktogether';
import VIDEO_INTRO from './../../assets/video1.mp4';
// import { OurServices } from './our-services';
import { TechStack } from './tech-stack';
import GalleryPage from './gallery';
import { useInView } from 'react-intersection-observer';
import CLOSE_BTN_IMG from '../../assets/images/cancel.png';

const IntroPage = () => {
    // const [showVideoplayer, setShowVideoplayer] = useState<boolean>(false);
    const [isVideoEnded, setIsVideoEnded] = useState(false);
    const videoPlayerRef = useRef<HTMLVideoElement>(null);
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.5,
    });
    // const gotoVideo = () => {
    //     setShowVideoplayer(true);
    //     if (videoPlayerRef && videoPlayerRef.current) {
    //         videoPlayerRef.current.play();
    //         videoPlayerRef.current.controls = true;
    //     }
    // }
    const [headingIndex, setHeadingIndex] = useState(0);
    const pages: ((props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement)[] = [
        ({ style }) => <animated.h1 className='m-heading-2 position-absolute' style={{ ...style, transformOrigin: '100% 0%', lineHeight: 1.4 }}>
            We are a software development company specializing in growth
            through strategic and creative thinking and technical expertise.
        </animated.h1>,
        ({ style }) => <animated.h1 className='m-heading-2 position-absolute' style={{ ...style, transformOrigin: '100% 0%', lineHeight: 1.4 }}>
            "We are experts in software development aimed at driving
            growth."
        </animated.h1>,
        ({ style }) => <animated.h1 className='m-heading-2 position-absolute' style={{ ...style, transformOrigin: '100% 0%', lineHeight: 1.4 }}>
            "Our emphasis is on the combination of strategic thinking,
            creative problem-solving, and technical proficiency."
        </animated.h1>,
        ({ style }) => <animated.h1 className='m-heading-2 position-absolute' style={{ ...style, transformOrigin: '100% 0%', lineHeight: 1.4 }}>
            Our goal is to turn ideas into reality through innovative software
            development.
        </animated.h1>,
        ({ style }) => <animated.h1 className='m-heading-2 position-absolute' style={{ ...style, transformOrigin: '100% 0%', lineHeight: 1.4 }}>
            We deliver exceptional services within the realm of opportunities.
        </animated.h1>,
    ];
    const transRef = useSpringRef();
    const transitions = useTransition(headingIndex, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: 'translate(0%,200%)', scale: 0, config: { duration: 1500 } },
        enter: { opacity: 1, transform: 'translate(0%,0%)', scale: 1, config: { duration: 500 } },
        leave: { opacity: 0, transform: 'translate(0%,-100%)', config: { duration: 1000 } },
    });
    useEffect(() => {
        transRef.start();
    }, [headingIndex]);
    useEffect(() => {
        const timerId = setInterval(() => {
            let newIndex = headingIndex + 1;
            if (newIndex > pages.length - 1) {
                newIndex = 0;
            }
            setHeadingIndex(newIndex);
        }, 5000);
        // window.scrollTo(0, 0);
        return () => clearInterval(timerId);
    });

    return (
        <div style={{ marginTop: 100 }}>
            <div className="container">
                <div className='row'>
                    <div className="col-lg-4 position-relative text-center d-none d-lg-block">
                        <img src={ARROW_DOWN_IMG} alt="" className='position-absolute' style={{ top: '40%' }} />
                    </div>
                    <div className="col-lg-8 overflow-hidden position-relative h-170 h-md-200">
                        {/* <h1 className='m-heading-1 anim-SlideUP'>We’re a strategic, creative growth agency with a love for branding.</h1>
                        <h1 className='m-heading-1 anim-SpawnFromBottom'>We’re a strategic, creative growth agency with a love for branding.</h1> */}
                        {
                            transitions((style: any, i: any) => {
                                const Page = pages[i]
                                return <Page style={style} />
                            })
                        }
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-lg-4">
                        <h4 className='mt-5 pt-lg-5'>We help new products to launch and existing business to scale.</h4>
                    </div>
                    <div className="col-lg-8">
                        {/* {!showVideoplayer && <div className="videoplayer-dummy">
                            <div className="placeholder d-flex align-items-center justify-content-center p-5 position-relative">
                                <p className='mb-0 text-white fnt-20'>info@mrsoftsolutions.com</p>
                                <div className='position-absolute cursor' style={{ right: 20 }}>
                                    <a href="#video-container"><img onClick={gotoVideo} src={PLAY_REEL_BTN} alt="" className='w-75 h-75' /></a>
                                </div>
                            </div>
                        </div>} */}
                        <div className="p-2 p-lg-5 bg-black position-relative videoplayer" ref={ref}>
                            <div className={`${(!inView && !isVideoEnded) ? 'fixed-player' : 'h-100'}`} >
                                <video onEnded={() => { setIsVideoEnded(true) }} ref={videoPlayerRef} style={{ maxWidth: '100%' }} src={VIDEO_INTRO} autoPlay muted controls>
                                </video>
                                <img src={CLOSE_BTN_IMG} alt="" className='position-absolute close-btn' style={{ top: -30, right: 0, width: 30, backgroundColor: '#ffffff', borderRadius: '50%' }} onClick={() => { setIsVideoEnded(true) }} />
                            </div>
                            {/* {!showVideoplayer && <div className='position-absolute cursor' style={{ right: 20, top: '40%' }}>
                                <img onClick={gotoVideo} src={PLAY_REEL_BTN} alt="" className='w-75 h-75' />
                            </div>} */}
                        </div>
                    </div>
                </div>
                {/* <div className="row mb-5" id="video-container">
                    <div className="col-12 p-5">
                        <div className="videoplayer p-5 bg-black" style={{ borderRadius: 50 }}>
                            <video ref={videoPlayerRef} style={{ maxWidth: '100%' }} src={VIDEO_INTRO} controls></video> */}
                {/* <div className="placeholder d-flex align-items-center justify-content-center p-5 position-relative">
                                <div className='position-absolute cursor' style={{ right: 20 }}>
                                    <a href="#video-container"><img src={PLAY_REEL_BTN} alt="" /></a>
                                </div>
                            </div> */}
                {/* </div>
                    </div>
                </div> */}
            </div>
            <CaseStudyPage></CaseStudyPage>
            {/* <OurServices></OurServices> */}
            {/* <ServicesPage></ServicesPage> */}
            {/* <OurApproach></OurApproach> */}
            {/* <Portfolio></Portfolio> */}
            <NewServicesPage></NewServicesPage>
            <ClientListPage></ClientListPage>
            {/* <BlogsPage></BlogsPage> */}
            <TechStack></TechStack>
            <WorkTogetherPage></WorkTogetherPage>
            <GalleryPage></GalleryPage>


        </div>
    )
}

export default IntroPage;