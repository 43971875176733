import React from 'react'
import Linkedin from '../../assets/images/social-icons/ico-lin-blk.svg';
import Facebook from '../../assets/images/social-icons/ico-fb-blk.svg';
import Twitter from '../../assets/images/social-icons/ico-twi-blk.svg';
import Mrlogo from '../../assets/images/logo.png';
import { Link, useLocation } from 'react-router-dom';


const AppHeader = () => {
    const location = useLocation();

    return (
        <div>
            <div className="position-fixed m-logo d-none d-lg-block">
                <p className='p-5'>© ALL RIGHTS RESERVED
                    <span className='p-2'><a target={'_blank'} href='https://www.linkedin.com/company/mrsoftsolutionsind/'><img src={Linkedin} alt="" /></a></span>
                    {/* <span className='p-2'><img src={Facebook} alt="" /></span>
                    <span className='p-2'><img src={Twitter} alt="" /></span> */}
                </p>
            </div>
            <div className='m-header px-5  d-flex align-items-center position-fixed w-100 justify-content-between bg-white shadow'>
                <Link to={'/'} className='text-decoration-none text-dark'>
                    <div className='d-flex align-items-center'>
                        <img src={Mrlogo} alt="" style={{ height: 60 }}></img>
                        <div className='ms-2'>
                            <h6 className='m-title-fnt mb-0' style={{ lineHeight: 1 }}>
                                MRSOFT SOLUTIONS</h6>
                            <small className='text-primary text-end mb-0'><i>Here’s Solution……</i></small>
                        </div>
                    </div>
                </Link>
                <div>
                    {location.pathname !== '/' && <Link to={'/'} className='me-lg-4 text-decoration-none'>Home</Link>}
                    {location.pathname !== '/services' && <Link to={'/services'} className='d-none d-lg-inline-block me-4 text-decoration-none'>Services</Link>}
                    {location.pathname !== '/about' && <Link to={'/about'} className='d-none d-lg-inline-block me-4 text-decoration-none'>About</Link>}
                    {/* <button className="btn btn-sm btn-black btn-round-lg ms-auto">Let's Talk</button>
                    <img src={Humberger} alt="" className='ms-4' /> */}
                </div>
            </div>
        </div>
    )
}

export default AppHeader;