import React, { useEffect } from 'react'
import ANDROID_IMG from '../../assets/images/technologies/android.png';
import ANGULAR_IMG from '../../assets/images/technologies/angular.png';
import FLUTTER_IMG from '../../assets/images/technologies/flutter.png';
import IOS_IMG from '../../assets/images/technologies/ios.png';
import MONGO_IMG from '../../assets/images/technologies/mongodb.png';
import NODEJS_IMG from '../../assets/images/technologies/node-js-tutorial.png';
import PHP_IMG from '../../assets/images/technologies/php.png';
import REACT_IMG from '../../assets/images/technologies/react.png';
import REACT_NATIVE_IMG from '../../assets/images/technologies/reactnative.png';
import RUBYRAILS_IMG from '../../assets/images/technologies/rubyrails.png';
import JAVA_IMG from '../../assets/images/technologies/java.png';
import MYSQL_IMG from '../../assets/images/technologies/MySql.png';
import POSTGRESQL_IMG from '../../assets/images/technologies/postgresql.png';
import MARIADB_IMG from '../../assets/images/technologies/Maria DB.png';
import DYNAMODB_IMG from '../../assets/images/technologies/Dynamo DB.png';
import GCS_IMG from '../../assets/images/technologies/gcp.png';
import AWS1_IMG from '../../assets/images/technologies/aws1.png';
import PHASER_IMG from '../../assets/images/technologies/phaser.png';
import UNITY3D_IMG from '../../assets/images/technologies/unity.png';
import COCOS_IMG from '../../assets/images/technologies/Cocos2D.png';
import HTML5_IMG from '../../assets/images/technologies/html5.png';
import JQUERY_IMG from '../../assets/images/technologies/jquery.png';
import HTML_IMG from '../../assets/images/technologies/html.png';
import AJAX_IMG from '../../assets/images/technologies/ajax.png';
import AOS from 'aos';



export const TechStack = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    const mobileTechs = [
        { img: REACT_NATIVE_IMG, height: 80 },
        { img: FLUTTER_IMG, height: 60 },
        { img: ANDROID_IMG, height: 80 },
        { img: IOS_IMG, height: 120 },
    ];
    const webTechs = [
        { img: NODEJS_IMG, height: 60 },
        { img: REACT_IMG, height: 60 },
        { img: ANGULAR_IMG, height: 60 },
        { img: HTML_IMG, height: 60 },
        // { img: JQUERY_IMG, height: 60 },
        // { img: AJAX_IMG, height: 80 },
    ];
    const backendTechs = [
        { img: NODEJS_IMG, height: 60 },
        { img: PHP_IMG, height: 50 },
        { img: RUBYRAILS_IMG, height: 80 },
        { img: JAVA_IMG, height: 80 },
    ];
    const gamingTechs = [
        { img: HTML5_IMG, height: 70 },
        { img: UNITY3D_IMG, height: 60 },
        { img: PHASER_IMG, height: 70 },
        { img: COCOS_IMG, height: 70 },
    ];
    const dbTechs = [
        { img: MYSQL_IMG, height: 60 },
        { img: POSTGRESQL_IMG, height: 60 },
        { img: MONGO_IMG, height: 70 },
        { img: MARIADB_IMG, height: 70 },
        { img: DYNAMODB_IMG, height: 50 },
    ];
    const cloudTechs = [
        { img: AWS1_IMG, height: 80 },
        { img: GCS_IMG, height: 70 },
    ];


    return (
        <div className="container-fluid" style={{ backgroundColor: '#F8F8F9' }}>
            <div className="py-5">
                <h1 className='m-heading-2 py-3 text-center'>Techstack</h1>
                <div className='px-lg-5 row'>
                    <div className="border-bottom pb-1 mb-4 col-lg-6">
                        <h4 className='text-center'>Mobile</h4>
                        <div className="d-grid d-lg-flex justify-content-center align-items-center" style={{ height: 120, gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                            {mobileTechs.map((el, index) => <img key={index} data-aos={index % 2 === 0 ? 'fade-up' : 'fade-down'} src={el.img} alt="" className='me-lg-4' height={el.height} style={{ objectFit: 'contain' }} />)}
                        </div>
                    </div>
                    <div className="border-bottom pb-1 mb-4 col-lg-6">
                        <h4 className='text-center'>Web</h4>
                        <div className="d-grid d-lg-flex justify-content-center align-items-center" style={{ height: 120, gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                            {webTechs.map((el, index) => <img key={index} data-aos={index % 2 === 0 ? 'fade-up' : 'fade-down'} src={el.img} alt="" className='me-lg-4' height={el.height} style={{ objectFit: 'contain' }} />)}
                        </div>
                    </div>
                    <div className="border-bottom pb-1 mb-4 col-lg-6">
                        <h4 className='text-center'>Backend</h4>
                        <div className="d-grid d-lg-flex justify-content-center align-items-center" style={{ height: 120, gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                            {backendTechs.map((el, index) => <img key={index} data-aos={index % 2 === 0 ? 'fade-up' : 'fade-down'} src={el.img} alt="" className='me-lg-4' height={el.height} style={{ objectFit: 'contain' }} />)}
                        </div>
                    </div>
                    <div className="border-bottom pb-1 mb-4 col-lg-6">
                        <h4 className='text-center'>Gaming</h4>
                        <div className="d-grid d-lg-flex justify-content-center align-items-center" style={{ height: 120, gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                            {gamingTechs.map((el, index) => <img key={index} data-aos={index % 2 === 0 ? 'fade-up' : 'fade-down'} src={el.img} alt="" className='me-lg-4' height={el.height} style={{ objectFit: 'contain' }} />)}
                        </div>
                    </div>
                    <div className="pb-1 mb-4 col-lg-6">
                        <h4 className='text-center'>Database</h4>
                        <div className="d-grid d-lg-flex justify-content-center align-items-center" style={{ height: 120, gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
                            {dbTechs.map((el, index) => <img key={index} data-aos={index % 2 === 0 ? 'fade-up' : 'fade-down'} src={el.img} alt="" className='me-lg-4' height={el.height} style={{ objectFit: 'contain' }} />)}
                        </div>
                    </div>
                    <div className="pb-1 mb-4 col-lg-6">
                        <h4 className='text-center'>Cloud</h4>
                        <div className="d-flex justify-content-center align-items-center" style={{ height: 120 }}>
                            {cloudTechs.map((el, index) => <img key={index} data-aos={index % 2 === 0 ? 'fade-up' : 'fade-down'} src={el.img} alt="" className='me-lg-4' height={el.height} style={{ objectFit: 'contain' }} />)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
