import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppHeader from '../components/header';
import { AboutUS } from './about-us';
import { AllServices } from './all-services';
import IntroPage from './intro';
import { Terms } from './terms';
import { PrivacyPolicy } from './privacy';

const HomePage = () => {
    return (
        <div>
            {/* <div className="container"> */}
            <BrowserRouter>
                <AppHeader></AppHeader>
                <Routes>
                    <Route path='' element={<IntroPage />}></Route>
                    <Route path='services' element={<AllServices />}></Route>
                    <Route path='about' element={<AboutUS />}></Route>
                    <Route path='terms' element={<Terms />}></Route>
                    <Route path='privacy-policy' element={<PrivacyPolicy />}></Route>
                </Routes>
            </BrowserRouter>
            {/* </div> */}
        </div>
    )
}

export default HomePage;