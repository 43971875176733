import React, { CSSProperties, useEffect, useRef, useState } from "react";
// import { useSpringRef, useTransition } from "react-spring";
import ARROW_RIGHT from "./../../assets/images/arrow-right-small.svg";
import COMPLEXBUSINESSAPP_IMG from "../../assets/images/service-images/Complex Business Applications.jpg";
import MOBILEAPP_IMG from "../../assets/images/service-images/Mobile_Apps.jpg";
import VIDEOSTREAM_IMG from "../../assets/images/service-images/Video Streaming Applications.jpg";
import GAMEDEV_IMG from "../../assets/images/service-images/Game_Development.jpg";
import ANALYTICSBI_IMG from "../../assets/images/service-images/Analytics & BI.jpg";
import CLOUDADM_IMG from "../../assets/images/service-images/Cloud Administration.jpg";
import { NavLink } from "react-router-dom";

const NewServicesPage = () => {
  let previousTimer: NodeJS.Timer;
  const onNext = () => {

    setHeadingIndex(pSlide => {
      let slide = pSlide + 1;
      if (slide > 5) {
        slide = 0;
      }
      return slide;
    });
  };
  const onPrev = () => {
    let slide = headingIndex - 1;
    if (headingIndex <= 0) {
      slide = 5;
    }

    setHeadingIndex(slide);
  };
  const [headingIndex, setHeadingIndex] = useState(0);
  useEffect(() => {
    if (previousTimer) {
      clearTimeout(previousTimer);
    }
    previousTimer = setTimeout(() => {
      onNext();
    }, 4000);
    return () => {
      clearTimeout(previousTimer);
    }
  }, [headingIndex]);

  const getNavBtns = () => {
    return <div className="d-flex justify-content-start justify-content-between w-sm-100 ms-lg-auto mb-3">
      <div className="me-5 cursor text-primary" onClick={onPrev}>
        <img
          src={ARROW_RIGHT}
          alt=""
          className="me-1"
          style={{ transform: "rotate(180deg)" }}
        ></img>{" "}
        Previous
      </div>
      <div className="cursor text-primary" onClick={onNext}>
        Next<img src={ARROW_RIGHT} alt="" className="ms-1"></img>
      </div>
    </div>;
  }
  return (
    <div className="pt-5" style={{ backgroundColor: '#F8F8F9' }}>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="m-0">Our Services</h4>
          <NavLink to={'services'} className="btn btn-link"><h5 className="m-0">View all</h5></NavLink>
        </div>
        {headingIndex === 0 && <div className="">
          <div className="d-flex flex-column-reverse flex-lg-row align-items-center mt-4 mb-3">
            <h1 className="m-heading-2 anim-top-to-bottom">Complex Business Applications</h1>
            {getNavBtns()}
          </div>
          <div className="row h-sm-unset" style={{ height: 550 }}>
            <div className="col-lg-6">
              <div className="">
                <p className="text-secondary anim-left-to-right">We Design sophisticated software programs that are custom designed to meet the specific needs of each organization, leveraging cutting edge technologies and best practices to deliver efficient, scalable, and reliable software for complex business processes.</p>
              </div>
              <div className="mt-4">
                <div className="row anim-bottom-to-top">
                  <div className="col-lg-6">
                    <ul className="list-unstyled">
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Artificial Intelligence</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Machine Learning</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Big Data Analytics</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Financial Services</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Healthcare</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Retail</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Logistics</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Transportation</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Energy</h5>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul className="list-unstyled">
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Utilities</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Education</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Insurance</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Delivery Services</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Supply Chain Management</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Range of services Application</h5>
                      </li>
                      <li>
                        <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Live Streaming Applications</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={COMPLEXBUSINESSAPP_IMG} alt="" className="anim-right-to-left w-100" style={{
                height: 350,
                objectFit: "contain",
                backgroundColor: "#ffffff",
              }} />
            </div>
          </div>
        </div>}
        {headingIndex === 1 && <div className="">
          <div className="d-flex flex-column flex-column-reverse flex-lg-row align-items-center mt-4 mb-3">
            <h1 className="m-heading-2 anim-top-to-bottom ">Mobile Applications</h1>
            {getNavBtns()}
          </div>
          <div className="row h-sm-unset" style={{ height: 550 }}>
            <div className="col-lg-6">
              <div className="">
                <p className="text-secondary anim-left-to-right">
                  Our team of experts is skilled in creating custom mobile
                  applications for both iOS and Android platforms which are
                  intuitive, efficient, and engaging mobile experiences that
                  drive user engagement and business growth.
                </p>
              </div>
              <div className="mt-4 anim-bottom-to-top">
                <ul className="list-unstyled">
                  <li>
                    <h5 className="">➜ Consumer Mobile</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ B2B Mobile</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Enterprise Mobility</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Cross-platform</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Games</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">
                      ➜ Powerful tool for remote teams Social Media
                      functionality Apps
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={MOBILEAPP_IMG}
                alt=""
                className="anim-right-to-left w-100"
                style={{
                  height: 350,
                  objectFit: "contain",
                  backgroundColor: "#ffffff",
                }}
              />
            </div>
          </div>
        </div>}
        {headingIndex === 2 && <div className="">
          <div className="d-flex flex-column flex-column-reverse flex-lg-row align-items-center mt-4 mb-3">
            <h1 className="m-heading-2 anim-top-to-bottom ">
              Video Streaming Applications Live & VOD
            </h1>
            {getNavBtns()}
          </div>
          <div className="row h-sm-unset" style={{ height: 550 }}>
            <div className="col-lg-6">
              <div className="">
                <p className="text-secondary anim-left-to-right">
                  Developing video streaming applications requires a deep
                  understanding of the complex technologies and architectures
                  that can handle large amounts of traffic and provide fast,
                  uninterrupted streaming and can provide the best possible user
                  experience.
                </p>
              </div>
              <div className="mt-4 anim-bottom-to-top">
                <ul className="list-unstyled">
                  <li>
                    <h5 className="">➜ Live streaming</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ On demand video platforms</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ RTMP, HLS</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ DASH</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Video compression</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Content delivery networks (CDNs)</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Adaptive bitrate streaming</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ User experience design</h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={VIDEOSTREAM_IMG}
                alt=""
                className="anim-right-to-left w-100"
                style={{
                  height: 350,
                  objectFit: "contain",
                  backgroundColor: "#ffffff",
                }}
              />
            </div>
          </div>
        </div>}
        {headingIndex === 3 && <div className="">
          <div className="d-flex flex-column flex-column-reverse flex-lg-row align-items-center mt-4 mb-3">
            <h1 className="m-heading-2 anim-top-to-bottom ">
              Game Development/ Multiplayer Games
            </h1>
            {getNavBtns()}
          </div>
          <div className="row h-sm-unset" style={{ height: 550 }}>
            <div className="col-lg-6">
              <div className="">
                <p className="text-secondary anim-left-to-right">
                  Developing a multiplayer game requires specialized knowledge
                  and expertise in network programming, server architecture, and
                  matchmaking systems. Multiplayer games must be designed with
                  scalability, reliability, and security in mind to provide a
                  seamless and enjoyable gaming experience for players.
                </p>
              </div>
              <div className="row mt-4 anim-bottom-to-top">
                <div className="col-lg-6">
                  <h5>Card Games:</h5>
                  <ul className="list-unstyled ps-4">
                    <li>
                      <h6 className="">➜ Rummy</h6>
                    </li>
                    <li>
                      <h6 className="py-2">➜ Poker</h6>
                    </li>
                    <li>
                      <h6 className="py-2">➜ Teenpatti</h6>
                    </li>
                  </ul>
                  <ul className="list-unstyled">
                    <li>
                      <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Casual Games, Multiplayer games</h5>
                    </li>
                    <li>
                      <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Strategy Games</h5>
                    </li>
                    <li>
                      <h5 className="py-lg-2 f-sm-16 f-sm-reg">
                        ➜ Gamification of applications and user experience
                      </h5>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <h5>Casino Games:</h5>
                  <ul className="list-unstyled ps-4">
                    <li>
                      <h6 className="py-2">➜ Baccarat</h6>
                    </li>
                    <li>
                      <h6 className="py-2">➜ Blackjack</h6>
                    </li>
                    <li>
                      <h6 className="py-2">➜ Slot Machines</h6>
                    </li>
                    <li>
                      <h6 className="py-2">➜ Roulette</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                src={GAMEDEV_IMG}
                alt=""
                className="anim-right-to-left w-100"
                style={{
                  height: 350,
                  objectFit: "contain",
                  backgroundColor: "#ffffff",
                }}
              />
            </div>
          </div>
        </div>}
        {headingIndex === 4 && <div className="">
          <div className="d-flex flex-column flex-column-reverse flex-lg-row align-items-center mt-4 mb-3">
            <h1 className="m-heading-2 anim-top-to-bottom ">Analytics & BI</h1>
            {getNavBtns()}
          </div>
          <div className="row h-sm-unset" style={{ height: 550 }}>
            <div className="col-lg-6">
              <div className="">
                <p className="text-secondary anim-left-to-right">
                  We provide services in Analytics & Business Intelligence (BI) software services which enable businesses to make data-driven decisions by providing insights into their operations and performance.
                </p>
              </div>
              <div className="mt-4 anim-bottom-to-top">
                <ul className="list-unstyled">
                  <li>
                    <h5 className="">➜ Collecting, analyzing</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Visualizing data from various sources</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Data mining</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Predictive analytics</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Machine learning to identify patterns</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">
                      ➜ Trends in the data
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={ANALYTICSBI_IMG} alt="" className="anim-right-to-left w-100" style={{
                height: 350,
                objectFit: "contain",
                backgroundColor: "#ffffff",
              }} />
            </div>
          </div>
        </div>}
        {headingIndex === 5 && <div className="">
          <div className="d-flex flex-column flex-column-reverse flex-lg-row align-items-center mt-4 mb-3">
            <h1 className="m-heading-2 anim-top-to-bottom ">Cloud Administration AWS / GCP</h1>
            {getNavBtns()}
          </div>
          <div className="row h-sm-unset" style={{ height: 550 }}>
            <div className="col-lg-6">
              <div className="">
                <p className="text-secondary anim-left-to-right">
                  We provide Services in Cloud Administration for AWS / GCP,
                  With a deep understanding of AWS and GCP, we help our clients
                  take full advantage of cloud technologies and stay ahead of
                  the curve in todays competitive business landscape.
                </p>
              </div>
              <div className="mt-4 anim-bottom-to-top">
                <ul className="list-unstyled">
                  <li>
                    <h5 className="">➜ Ongoing maintenance</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ monitoring services</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Performing optimally</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Troubleshoot and resolve</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Expert management</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">
                      ➜ Optimization of cloud infrastructure for businesses
                    </h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Scalable</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Secure</h5>
                  </li>
                  <li>
                    <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Reliable cloud environments</h5>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={CLOUDADM_IMG} alt="" className="anim-right-to-left w-100" />
            </div>
          </div>
        </div>}

      </div>
    </div>

  );
};

export default NewServicesPage;
