import React, { useEffect } from 'react'
import COMPLEXBUSINESSAPP_IMG from '../../assets/images/service-images/Complex Business Applications.jpg'
import MOBILEAPP_IMG from '../../assets/images/service-images/Mobile_Apps.jpg'
import VIDEOSTREAM_IMG from '../../assets/images/service-images/Video Streaming Applications.jpg'
import GAMEDEV_IMG from '../../assets/images/service-images/Game_Development.jpg'
import ANALYTICSBI_IMG from '../../assets/images/service-images/Analytics & BI.jpg'
import CLOUDADM_IMG from '../../assets/images/service-images/Cloud Administration.jpg';
import AOS from 'aos';
import WorkTogetherPage from './worktogether'

export const AllServices = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={{ marginTop: 80 }}>
            <div className="container overflow-hidden">
                <h1 className='m-heading-1 py-4 text-center'>Our Services</h1>
                <div className="row mb-4">
                    <div className="col-lg-6 py-lg-5" data-aos="fade-right">
                        <h1 className="m-heading-2">Complex Business Applications</h1>
                        <p className='text-secondary mt-2'>We Design sophisticated software programs that are custom designed to meet the specific needs of each organization, leveraging cutting edge technologies and best practices to deliver efficient, scalable, and reliable software for complex business processes.</p>
                        <img src={COMPLEXBUSINESSAPP_IMG} alt="" className='mt-2 d-none d-lg-block' />
                    </div>
                    <div className="col-lg-6 py-lg-5 position-relative" data-aos="fade-left">
                        <ul className="list-unstyled">
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Artificial Intelligence</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Machine Learning</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Big Data Analytics</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Financial Services</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Healthcare</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Retail</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Logistics</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Transportation</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Energy</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Utilities</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Education</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Insurance</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Delivery Services</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Supply Chain Management</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Range of services Application</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Live Streaming Applications</h5>
                            </li>
                        </ul>

                    </div>
                </div>
                <div className="row flex-lg-row flex-column-reverse mb-4">
                    <div className="col-lg-6 py-lg-5" data-aos="fade-right">
                        <ul className="list-unstyled">
                            <li>
                                <h5 className="">➜ Consumer Mobile</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ B2B Mobile</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Enterprise Mobility</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Cross-platform</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Games</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">
                                    ➜ Powerful tool for remote teams Social Media
                                    functionality Apps
                                </h5>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 py-lg-5" data-aos="fade-left">
                        <h1 className="m-heading-2">Mobile Applications</h1>
                        <p className='text-secondary mt-2'>Our team of experts is skilled in creating custom mobile applications for both iOS and Android platforms which are intuitive, efficient, and engaging mobile experiences that drive user engagement and business growth.</p>
                        <div className="text-center">
                            <img src={MOBILEAPP_IMG} alt="" className='mt-2 w-50 d-none d-lg-block' />
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-6 py-lg-5" data-aos="fade-right">
                        <h1 className="m-heading-2">Video Streaming Applications Live & VOD</h1>
                        <p className='text-secondary mt-2'>Developing video streaming applications requires a deep understanding of the complex technologies and architectures that can handle large amounts of traffic and provide fast, uninterrupted streaming and can provide the best possible user experience.</p>
                        <div className="text-center">
                            <img src={VIDEOSTREAM_IMG} alt="" className='w-50 mt-2 d-none d-lg-block' />
                        </div>
                    </div>
                    <div className="col-lg-6 py-lg-5" data-aos="fade-left">
                        <ul className="list-unstyled">
                            <li>
                                <h5 className="">➜ Live streaming</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ On demand video platforms</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ RTMP, HLS</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ DASH</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Video compression</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Content delivery networks (CDNs)</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Adaptive bitrate streaming</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ User experience design</h5>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row flex-lg-row flex-column-reverse mb-4">
                    <div className="col-lg-6 py-lg-5" data-aos="fade-right">
                        <h5>Card Games:</h5>
                        <ul className="list-unstyled ps-4">
                            <li>
                                <h6 className="">➜ Rummy</h6>
                            </li>
                            <li>
                                <h6 className="py-2">➜ Poker</h6>
                            </li>
                            <li>
                                <h6 className="py-2">➜ Teenpatti</h6>
                            </li>
                        </ul>
                        <h5>Casino Games:</h5>
                        <ul className="list-unstyled ps-4">
                            <li>
                                <h6 className="py-2">➜ Baccarat</h6>
                            </li>
                            <li>
                                <h6 className="py-2">➜ Blackjack</h6>
                            </li>
                            <li>
                                <h6 className="py-2">➜ Slot Machines</h6>
                            </li>
                            <li>
                                <h6 className="py-2">➜ Roulette</h6>
                            </li>
                        </ul>
                        <ul className="list-unstyled">
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Casual Games, Multiplayer games</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Strategy Games</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">
                                    ➜ Gamification of applications and user experience
                                </h5>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6 py-lg-5" data-aos="fade-left">
                        <h1 className="m-heading-2">Game Development/ Multiplayer Games</h1>
                        <p className='text-secondary mt-2'>Developing a multiplayer game requires specialized knowledge and expertise in network programming, server architecture, and matchmaking systems. Multiplayer games must be designed with scalability, reliability, and security in mind to provide a seamless and enjoyable gaming experience for players.</p>
                        <div className="text-center">
                            <img src={GAMEDEV_IMG} alt="" className='mt-2 w-50 d-none d-lg-block' />
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-lg-6 py-lg-5" data-aos="fade-right">
                        <h1 className="m-heading-2">Analytics & BI</h1>
                        <p className='text-secondary mt-2'>We provide services in Analytics & Business Intelligence (BI) software services which enable businesses to make data-driven decisions by providing insights into their operations and performance.</p>
                        <img src={ANALYTICSBI_IMG} alt="" className='mt-2 d-none d-lg-block' />
                    </div>
                    <div className="col-lg-6 py-lg-5" data-aos="fade-left">
                        <ul className="list-unstyled">
                            <li>
                                <h5 className="">➜ Collecting, analyzing</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Visualizing data from various sources</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Data mining</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Predictive analytics</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Machine learning to identify patterns</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">
                                    ➜ Trends in the data
                                </h5>
                            </li>
                        </ul>

                    </div>
                </div>
                <div className="row flex-lg-row flex-column-reverse mb-4">
                    <div className="col-lg-6 py-lg-5" data-aos="fade-right">
                        <ul className="list-unstyled">
                            <li>
                                <h5 className="">➜ Ongoing maintenance</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ monitoring services</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Performing optimally</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Troubleshoot and resolve</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Expert management</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">
                                    ➜ Optimization of cloud infrastructure for businesses
                                </h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Scalable</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Secure</h5>
                            </li>
                            <li>
                                <h5 className="py-lg-2 f-sm-16 f-sm-reg">➜ Reliable cloud environments</h5>
                            </li>
                        </ul>

                    </div>
                    <div className="col-lg-6 py-lg-5" data-aos="fade-left">
                        <h1 className="m-heading-2">Cloud Administration AWS / GCP</h1>
                        <p className='text-secondary mt-2'>We provide Services in Cloud Administration for AWS / GCP, With a deep understanding of AWS and GCP, we help our clients take full advantage of cloud technologies and stay ahead of the curve in todays competitive business landscape.</p>
                        <img src={CLOUDADM_IMG} alt="" className='mt-2 d-none d-lg-block' />
                    </div>
                </div>
            </div>
            <div className="pt-4 border-top">
                <WorkTogetherPage></WorkTogetherPage>
            </div>
        </div >
    )
}
